import React from 'react';

import { frontendConfig } from '../../../../../core/system/config/frontend.server.js';
import TeaserGrade from '../../teasers/teaserComponents/TeaserGrade.js';

interface RecommendationCardProps {
  id: string;
  title: string;
  grade: number;
  area?: string;
}

const vinkUrl =
  frontendConfig.serverRuntime === 'convox-production'
    ? 'https://vink.aftenposten.no/artikkel/'
    : '/artikkel/';

const RecommendationCard: React.FC<RecommendationCardProps> = ({
  id,
  title,
  grade,
  area,
}) => {
  return (
    <a href={vinkUrl + id} className="recommendation-card">
      <div className="recommendation-card__title">{title}</div>
      <div className="recommendation-card__info">
        {area && <div className="recommendation-card__info__area">{area}</div>}
        {area && <div className="recommendation-card__info__divider" />}
        <TeaserGrade grade={grade} />
      </div>
    </a>
  );
};

export default RecommendationCard;
