import React from 'react';

import RecommendationCard from './RecommendationCard.js';

type Recommendation = {
  id: string;
  title: string;
  grade: number;
  area?: string;
};

interface MessageProps {
  sender: string;
  text: string;
  recommendations?: Recommendation[];
  descriptions?: string[];
}

const MessageDesc: React.FC<MessageProps> = ({
  sender,
  text,
  recommendations,
  descriptions,
}) => {
  return (
    <div className={`message ${sender}`}>
      {text}
      {recommendations &&
        recommendations.map((rec, index) => (
          <div key={rec.id}>
            <RecommendationCard
              id={rec.id}
              title={rec.title}
              grade={rec.grade}
              area={rec.area}
            />
            <p>{descriptions?.[index] ?? ''}</p>
          </div>
        ))}
    </div>
  );
};

export default MessageDesc;
