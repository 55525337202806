import React from 'react';

import RecommendationCard from './RecommendationCard.js';

type Recommendation = {
  id: string;
  title: string;
  grade: number;
  area?: string;
};

interface MessageProps {
  sender: string;
  text: string;
  recommendations?: Recommendation[];
}

const MessageNoDesc: React.FC<MessageProps> = ({
  sender,
  text,
  recommendations,
}) => {
  return (
    <div className={`message ${sender}`}>
      {text}
      {recommendations &&
        recommendations.map((rec, recIndex) => (
          <RecommendationCard
            key={recIndex}
            id={rec.id}
            title={rec.title}
            grade={rec.grade}
            area={rec.area}
          />
        ))}
    </div>
  );
};

export default MessageNoDesc;
