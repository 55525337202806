import React from 'react';

const BotTypingIndicator: React.FC = () => {
  return (
    <div className="message-container bot loading">
      <div className="message-container bot typing-indicator">
        <div className="message typing">
          <span className="dot"></span>
          <span className="dot"></span>
          <span className="dot"></span>
        </div>
        <div className="message-icon bot" />
      </div>
    </div>
  );
};

export default BotTypingIndicator;
