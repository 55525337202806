import React from 'react';

import { frontendConfig } from '../../../../../core/system/config/frontend.server.js';

interface ChatButtonProps {
  onClick: () => void;
}

const ChatButton: React.FC<ChatButtonProps> = ({ onClick }) => {
  return (
    <button className="chat-icon" onClick={onClick}>
      <img
        className="chat-icon-img"
        src={`${frontendConfig.cdnUrlInternal}/vink/images/icons/Chat.svg`}
        alt="Chat Icon"
      />
      Chat
    </button>
  );
};

export default ChatButton;
