import React, { useRef, useEffect } from 'react';

import MessageNoDesc from './MessageNoDesc.js';
import MessageDesc from './MessageDesc.js';
import BotTypingIndicator from './BotTypingIndicator.js';
import ChatInput from './ChatInput.js';

type Recommendation = {
  id: string;
  title: string;
  grade: number;
  area?: string;
};

type MessageType = {
  text: string;
  sender: 'user' | 'bot';
  recommendations?: Recommendation[];
  descriptions?: string[];
};

interface ChatOpenProps {
  messages: MessageType[];
  isLoading: boolean;
  inputText: string;
  isMobile: boolean;
  toggleChat: () => void;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  sendMessage: (text: string, sender: 'user' | 'bot') => void;
}

const ChatOpen: React.FC<ChatOpenProps> = ({
  messages,
  isLoading,
  inputText,
  isMobile,
  toggleChat,
  handleInputChange,
  handleKeyDown,
  sendMessage,
}) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    setTimeout(() => {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <div className="chat-window">
      <div className="chat-header">
        <h3 className="header-title">Vink-Bot</h3>
        <button className="exit-button" onClick={toggleChat}>
          <img
            className="exit-button__icon"
            src="https://cdn.aftenposten.no/rnd/sprekere/icon/x.svg"
            alt="exit"
          />
        </button>
      </div>
      <div className="messages">
        {messages.map((message, index) => (
          <div key={index}>
            <div className={`message-container ${message.sender}`}>
              <MessageNoDesc
                sender={message.sender}
                text={message.text}
                recommendations={message.recommendations}
              />
              <div className={`message-icon ${message.sender}`} />
            </div>
            {message.descriptions && (
              <div className={`message-container ${message.sender}`}>
                <MessageDesc
                  sender={message.sender}
                  text={message.text}
                  recommendations={message.recommendations}
                  descriptions={message.descriptions}
                />
                <div className={`message-icon ${message.sender}`} />
              </div>
            )}
          </div>
        ))}
        {isLoading && <BotTypingIndicator />}
        <div ref={messagesEndRef} />
      </div>
      <ChatInput
        inputText={inputText}
        isMobile={isMobile}
        handleInputChange={handleInputChange}
        handleKeyDown={handleKeyDown}
        sendMessage={sendMessage}
      />
    </div>
  );
};

export default ChatOpen;
