import React from 'react';

import { frontendConfig } from '../../../../../core/system/config/frontend.server.js';

interface ChatInputProps {
  inputText: string;
  isMobile: boolean;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  sendMessage: (text: string, sender: 'user' | 'bot') => void;
}

const ChatInput: React.FC<ChatInputProps> = ({
  inputText,
  isMobile,
  handleInputChange,
  handleKeyDown,
  sendMessage,
}) => {
  return (
    <div className="input-container">
      <input
        className="input-field"
        type="text"
        placeholder="Aa"
        value={inputText}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
      />
      {!isMobile && (
        <button
          className="send-button"
          onClick={() => sendMessage(inputText, 'user')}
        >
          Send
          <img
            className="send-icon-img"
            src={`${frontendConfig.cdnUrlInternal}/vink/images/icons/Send.svg`}
            alt="Send Icon"
          />
        </button>
      )}
    </div>
  );
};

export default ChatInput;
