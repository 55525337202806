import React, { useState } from 'react';
import axios from 'axios';
import { useSessionStorage } from 'usehooks-ts';

import type { Tag } from '../../../../core/data-sources/frapi/data-types.js';

import ChatClosed from './chatBotComponents/ChatClosed.js';
import ChatOpen from './chatBotComponents/ChatOpen.js';
import { frontendConfig } from '../../../../core/system/config/frontend.server.js';

type ChatProps = {
  isMobile: boolean;
};

type Recommendation = {
  id: string;
  title: string;
  grade: number;
  area?: string;
};

type MessageType = {
  text: string;
  sender: 'user' | 'bot';
  recommendations?: Recommendation[];
  descriptions?: string[];
};

type Article = {
  id: string;
  kicker: string;
  grade: number;
  tags: Tag[];
};

type TeasersResponse = {
  [key: string]: Article;
};

type BotResponse = {
  data: {
    descriptions: string[];
    articleIds: string[];
    message: string;
  };
};

type ErrorType = {
  response: {
    status: number;
  };
};

const teasersUrl =
  frontendConfig.serverRuntime === 'convox-production'
    ? 'https://vink.aftenposten.no/api/teasers/'
    : '/api/teasers/';

const Chat: React.FC<ChatProps> = ({ isMobile }) => {
  const [isOpen, setIsOpen] = useSessionStorage<boolean>('chatIsOpen', false);
  const [messages, setMessages] = useSessionStorage<MessageType[]>('messages', [
    {
      text: 'Hallo, jeg er Vink-Bot! Hva har du lyst på i dag?',
      sender: 'bot',
    },
  ]);
  const [inputText, setInputText] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  const getLocation = (tags: Tag[]) => {
    const tagsArray = tags.filter((tag) => tag.title.startsWith('Vink/område'));
    const tagTitle = tagsArray[0]?.title.split('/')[2];

    return tagsArray.length > 0 ? tagTitle : '';
  };

  const sendMessage = async (text: string, sender: 'user' | 'bot') => {
    setMessages([...messages, { text, sender }]);
    setInputText('');
    await sendPostRequest(text);
  };

  const sendPostRequest = async (data: string) => {
    setIsLoading(true);
    const url = 'https://vink-bot.dev.convox.schibsted.tech/api/v2/sok';
    const headers = {
      'Content-Type': 'application/json',
    };
    const body = { message: data };
    try {
      const response = await axios.post(url, body, { headers });

      //const messageText: string = response.data.description; # Can be used to display the response from the bot
      await handleSuccessResponse(response);
    } catch (error) {
      handleErrorResponse(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSuccessResponse = async (response: BotResponse) => {
    const message = response.data.message;
    if (message) {
      setMessages((currentMessages) => [
        ...currentMessages,
        { text: message, sender: 'bot' },
      ]);

      return;
    }

    const articleIds: string = response.data.articleIds.join(',');
    const articles: TeasersResponse = await getTeasers(articleIds);
    const descriptions: string[] = response.data.descriptions;

    const recommendations: Recommendation[] = Object.values(articles).map(
      (article: Article) => ({
        id: article.id,
        title: article.kicker,
        grade: article.grade,
        area: getLocation(article.tags),
      }),
    );

    const defaultMessage =
      'Jeg fant noen artikler som passer til det du spurte om!';

    setMessages((currentMessages) => [
      ...currentMessages,
      {
        text: defaultMessage,
        sender: 'bot',
        recommendations,
        descriptions: descriptions,
      },
    ]);
  };

  const handleErrorResponse = (error: unknown) => {
    let errorMessage = 'Beklager, det har skjedd en feil, prøv igjen om litt.';

    if ((error as ErrorType).response?.status === 404) {
      errorMessage =
        'Beklager, jeg fant ingen artikler som passer til det du spurte om... Gjerne prøv et annet søk!';
    }

    setMessages((currentMessages) => [
      ...currentMessages,
      { text: errorMessage, sender: 'bot' },
    ]);
  };

  const getTeasers = async (id: string) => {
    const article = await axios.get(`${teasersUrl}${id}`);

    return article.data;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      sendMessage(inputText, 'user');
    }
  };

  return (
    <div
      className={`chat-container ${isOpen ? 'open' : ''} ${isMobile ? 'mobile' : ''}`}
    >
      {isOpen ? (
        <ChatOpen
          messages={messages}
          isLoading={isLoading}
          inputText={inputText}
          isMobile={isMobile}
          toggleChat={toggleChat}
          handleInputChange={handleInputChange}
          handleKeyDown={handleKeyDown}
          sendMessage={sendMessage}
        />
      ) : (
        <ChatClosed onClick={toggleChat} />
      )}
    </div>
  );
};

export default Chat;
