import type {
  ByfunnFrapiArticle,
  ByfunnPromoArticle,
} from '../../../../types/frapi-types.js';

export const getAuthorEmails = (
  article: ByfunnFrapiArticle | ByfunnPromoArticle,
) =>
  article.authors
    .map(({ contacts = [] }) =>
      contacts.reduce(
        (result, { type, value }) => (type === 'email' ? value : result),
        '',
      ),
    )
    .filter((email) => email.length > 0 && email.indexOf('@') > 0);
